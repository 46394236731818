var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?[_c('b-table',{attrs:{"borderless":"","fields":_vm.existingFields,"items":_vm.transactions,"tbody-tr-class":"hover"},scopedSlots:_vm._u([{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(_vm.transactionData[item.transactionType].icon)}}),_vm._v(" "+_vm._s(item.transactionType)+" "+_vm._s(item.pair.token1)+" "+_vm._s(_vm.transactionData[item.transactionType].between)+" "+_vm._s(item.pair.token2)+" ")])]}},{key:"cell(totalValue)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" $"+_vm._s(item.amountUSD)+" ")])]}},{key:"cell(amount1)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.amount1)+" "+_vm._s(item.pair.token1)+" ")])]}},{key:"cell(amount2)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.amount2)+" "+_vm._s(item.pair.token2)+" ")])]}},{key:"cell(account)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.from)+" ")])]}},{key:"cell(time)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getTime(item.timestamp))+" ")])]}}],null,false,993185435)})]:_vm._e(),(_vm.isMobile)?_vm._l((_vm.transactions),function(item,index){return _c('div',{key:'transaction'+index,staticClass:"pool"},[_c('div',{staticClass:"pool-header"},[_c('div',[_c('span',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(_vm.transactionData[item.transactionType].icon)}}),_vm._v(" "+_vm._s(item.transactionType)+" "+_vm._s(item.pair.token1)+" "+_vm._s(_vm.transactionData[item.transactionType].between)+" "+_vm._s(item.pair.token2)+" ")])]),_c('div',{staticClass:"pool-body"},[_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Total value:")]),_c('div',[_vm._v("$"+_vm._s(item.amountUSD))])]),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Token amount:")]),_c('div',[_vm._v(" "+_vm._s(item.amount1)+" "+_vm._s(item.pair.token1)+" ")])]),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Token amount:")]),_c('div',[_vm._v(" "+_vm._s(item.amount2)+" "+_vm._s(item.pair.token2)+" ")])]),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Account:")]),_c('div',[_vm._v(" "+_vm._s(item.from)+" ")])]),_c('div',{staticClass:"pool-row"},[_c('div',{staticClass:"caption mr-3"},[_vm._v("Time:")]),_c('div',[_vm._v(" "+_vm._s(_vm.getTime(item.timestamp))+" ")])])])])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }