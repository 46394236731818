<template>
    <div>
        <template v-if="!isMobile">
            <b-table borderless
                     :fields="existingFields"
                     :items="transactions"
                     tbody-tr-class="hover">

                <template v-slot:cell(type)="{item}">
                    <div>
                        <span v-html="transactionData[item.transactionType].icon" class="mr-2"></span>
                        {{ item.transactionType }}
                        {{ item.pair.token1}}
                        {{ transactionData[item.transactionType].between}}
                        {{item.pair.token2}}
                    </div>
                </template>
                <template v-slot:cell(totalValue)="{item}">
                    <div>
                        ${{ item.amountUSD }}
                    </div>
                </template>
                <template v-slot:cell(amount1)="{item}">
                    <div>
                        {{ item.amount1 }}
                        {{item.pair.token1}}
                    </div>
                </template>
                <template v-slot:cell(amount2)="{item}">
                    <div>
                        {{ item.amount2 }}
                        {{ item.pair.token2 }}
                    </div>
                </template>
                <template v-slot:cell(account)="{item}">
                    <div>
                        {{ item.from }}
                    </div>
                </template>
                <template v-slot:cell(time)="{item}">
                    <div>
                        {{ getTime(item.timestamp) }}
                    </div>
                </template>

            </b-table>
        </template>
        <template v-if="isMobile">
            <div class="pool" v-for="(item, index) in transactions" :key="'transaction'+index">
                <div class="pool-header">
                    <div>
                        <span v-html="transactionData[item.transactionType].icon" class="mr-2"></span>
                        {{ item.transactionType }}
                        {{ item.pair.token1}}
                        {{ transactionData[item.transactionType].between}}
                        {{item.pair.token2}}
                    </div>
                </div>
                <div class="pool-body">
                    <div class="pool-row">
                        <div class="caption mr-3">Total value:</div>
                        <div>${{ item.amountUSD }}</div>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Token amount:</div>
                        <div>
                            {{ item.amount1 }}
                            {{item.pair.token1}}
                        </div>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Token amount:</div>
                        <div>
                            {{ item.amount2 }}
                            {{item.pair.token2}}
                        </div>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Account:</div>
                        <div>
                            {{ item.from }}
                        </div>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Time:</div>
                        <div>
                            {{ getTime(item.timestamp) }}
                        </div>
                    </div>
                </div>
            </div>

        </template>

<!--        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" v-if="scroll">
            <span slot="no-more"></span>
            <div slot="no-results"></div>
        </infinite-loading> -->

    </div>

</template>

<script>
    import moment from "moment";

    export default {
        name: "TransactionsList",
        props: {
            transactions: Array,
            scroll: Boolean,
            existingFields: Array,
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 768;
            }
        },
        data: function () {
            return {
                transactionData: {
                    Swap: {name: 'simpleSwaps', between: 'for', icon: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path opacity="0.5" d="M12.001 2C6.50098 2 2.00098 6.5 2.00098 12C2.00098 17.5 6.50098 22 12.001 22C17.501 22 22.001 17.5 22.001 12C22.001 6.5 17.501 2 12.001 2ZM12.001 20C7.59098 20 4.00098 16.41 4.00098 12C4.00098 7.59 7.59098 4 12.001 4C16.411 4 20.001 7.59 20.001 12C20.001 16.41 16.411 20 12.001 20Z" fill="#B5B5C3"/>\n' +
                            '<path d="M15.001 9V6.5L18.501 10L15.001 13.5V11H11.001V9H15.001Z" fill="#B5B5C3"/>\n' +
                            '<path d="M5.50098 14L9.00098 10.5V13H13.001V15H9.00098V17.5L5.50098 14Z" fill="#B5B5C3"/>\n' +
                            '</svg>\n',},
                    Add: {name: 'deposits', between: 'and', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M0.000976562 10C0.000976562 4.477 4.47798 0 10.001 0C15.524 0 20.001 4.477 20.001 10C20.001 15.523 15.524 20 10.001 20C4.47798 20 0.000976562 15.523 0.000976562 10ZM10.001 2C7.87924 2 5.84441 2.84285 4.34412 4.34315C2.84383 5.84344 2.00098 7.87827 2.00098 10C2.00098 12.1217 2.84383 14.1566 4.34412 15.6569C5.84441 17.1571 7.87924 18 10.001 18C12.1227 18 14.1575 17.1571 15.6578 15.6569C17.1581 14.1566 18.001 12.1217 18.001 10C18.001 7.87827 17.1581 5.84344 15.6578 4.34315C14.1575 2.84285 12.1227 2 10.001 2Z" fill="#B5B5C3"/>\n' +
                            '<path d="M9.00098 5V9H5.00098V11H9.00098V15H11.001V11H15.001V9H11.001V5H9.00098Z" fill="#B5B5C3"/>\n' +
                            '</svg>\n'},
                    Withdraw: {name: 'withdrawals', between: 'and', icon: ''},
                    OrderPlaced: {name: 'delayedSwaps', between: 'and',  icon: ''},
                    OrderClaimed: {name: 'delayedSwaps', between: 'and',  icon: ''},
                },

                page: 1,
                size: 10,
                infiniteId: +new Date()
            }

        },
        methods: {
            getTime : function (time) {
                return moment.unix(time).fromNow();
            },

        }

    }
</script>

<style scoped>

</style>
